import { createEffect, createSignal, onCleanup } from "solid-js";

const heroHeadlineAdjectives = ["busy, busy", "unpredictable", "jetsetting", "commitment-phobic"];

export function HeroHeadline() {
  const [currentAdjective, setAdjective] = createSignal(heroHeadlineAdjectives[0]);

  createEffect(() => {
    const timer = setInterval(() => {
      setAdjective(heroHeadlineAdjectives[(Math.random() * heroHeadlineAdjectives.length) | 0]);
    }, 5000);

    onCleanup(() => clearTimeout(timer));
  });

  return (
    <span class="text-5xl md:text-6xl lg:text-7xl max-w-prose block font-extrabold">
      meet your
      <br />
      <span class="text-brand-400">{currentAdjective()}</span>
      <br />
      friends more often
    </span>
  );
}
